import emojiRegex from './emojiRegex';

export default function getEmojiFromStartOfString(input: string): string | undefined {
  const emoji = input.match(emojiRegex)?.[0];

  if (emoji) {
    // https://mts.io/2015/04/21/unicode-symbol-render-text-emoji
    return `${emoji}\uFE0F`;
  }
}
